<template>
  <div class="plan">
    <div class="d-flex" style="flex-direction:row-reverse">
      <div class="switch-date">
        <div
          @click="planDate = 'Yearly'"
          :class="{
            'switch-button': true,
            'switch-active': planDate === 'Yearly'
          }"
        >
          Yearly
        </div>
        <div
          @click="planDate = 'Monthly'"
          :class="{
            'switch-button': true,
            'switch-active': planDate === 'Monthly'
          }"
        >
          Monthly
        </div>
      </div>
    </div>
    <div class="plan-cover">
      <div class="plan-item">
        <h2>Basic</h2>
        <h5>Best for short-term crammers</h5>
        <div class="plan-fee free-text">FREE</div>
        <div class="plan-button free-button">
          Free forever
        </div>
        <div class="mb-4 text-center price-text">
          <small>&nbsp;</small>
        </div>
        <div class="plan-options">
          <b>Key Features:</b>
          <div
            class="plan-option"
            v-for="(item, index) in freeList"
            :key="index"
          >
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <span v-show="index > 6">
                <b class="logo-color mr-1">AI</b>
              </span>
              <span v-html="item"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="plan-item plan-pro">
        <h2>Pro</h2>
        <h5>Best when preparing for single exam</h5>
        <div v-show="planDate === 'Yearly'">
          <div class="plan-fee money-text">
            $9.99
          </div>
        </div>
        <div v-show="planDate === 'Monthly'">
          <div class="plan-fee money-text">
            $19.99
          </div>
        </div>
        <div class="plan-button money-button">
          <!-- Get Started -->
          Coming soon
        </div>
        <div class="mb-4 text-center price-text">
          <small>billed per user per month</small>
        </div>
        <div class="plan-options">
          <b>Key Features:</b>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <b>Unlimited Access</b> to 1 Exam:
              <span
                @click="setProType('SAT')"
                :class="{ activePro: ProType === 'SAT', 'pro-type': true }"
              >
                SAT</span
              >
              /
              <span
                @click="setProType('ACT')"
                :class="{ activePro: ProType === 'ACT', 'pro-type': true }"
                >ACT</span
              >
              /
              <span
                @click="setProType('PSAT')"
                :class="{ activePro: ProType === 'PSAT', 'pro-type': true }"
                >PSAT</span
              >
              /
              <span
                @click="setProType('AP')"
                :class="{ activePro: ProType === 'AP', 'pro-type': true }"
                >AP (1 Subject)</span
              >
              /
              <span
                @click="setProType('TOEFL')"
                :class="{ activePro: ProType === 'TOEFL', 'pro-type': true }"
                >TOEFL</span
              >
              /
              <span
                @click="setProType('IELTS')"
                :class="{ activePro: ProType === 'IELTS', 'pro-type': true }"
                >IELTS</span
              >
            </span>
          </div>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              100% Replica of the Bluebook/TestNav Platform
            </span>
          </div>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <b v-show="ProType === 'SAT'">20</b>
              <span v-show="ProType !== 'SAT'">2</span>
              Adaptive Full Length SATs
            </span>
          </div>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <b v-show="ProType === 'ACT'">40</b>
              <span v-show="ProType !== 'ACT'">4</span>
              Full Length ACTs
            </span>
          </div>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <b v-show="ProType === 'AP'">20</b>
              <span v-show="ProType !== 'AP'">2</span>
              Full Length APs (Coming Soon)
            </span>
          </div>
          <div class="plan-option">
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <b v-show="ProType === 'PSAT'">6</b>
              <span v-show="ProType !== 'PSAT'">1</span>
              Full Length
              <span v-show="ProType === 'PSAT'">
                PSATs
              </span>
              <span v-show="ProType !== 'PSAT'">
                PSAT
              </span>
              (Coming Soon)
            </span>
          </div>
          <div
            class="plan-option"
            v-for="(item, index) in proList"
            :key="index"
          >
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <span v-show="index > 0">
                <b class="logo-color mr-1">AI</b>
              </span>
              <span v-html="item"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="plan-item plan-max">
        <h2>Max<span class="plan-tag">Popular</span></h2>
        <h5>Best when preparing for multiple exams</h5>
        <div v-show="planDate === 'Yearly'">
          <div class="plan-fee money-text">
            $14.99
          </div>
        </div>
        <div v-show="planDate === 'Monthly'">
          <div class="plan-fee money-text">
            $29.99
          </div>
        </div>
        <div class="plan-button money-button">
          <!-- Get Started -->
          Coming soon
        </div>
        <div class="mb-4 text-center price-text">
          <small>billed per user per month</small>
        </div>
        <div class="plan-options">
          <b>Key Features:</b>
          <div
            class="plan-option"
            v-for="(item, index) in maxList"
            :key="index"
          >
            <span class="icon">
              <i class="fa fa-check"></i>
            </span>
            <span>
              <span v-show="index > 6">
                <b class="logo-color mr-1">AI</b>
              </span>
              <span v-html="item"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import plansApi from "@/apis/plans";
import moment from "moment";

export default {
  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      ProType: "SAT",
      ProTypes: ["SAT", "ACT", "PSAT", "AP","TOEFL", "IELTS" ],
      interval: null,
      planDate: "Yearly",
      freeList: [
        "Limited Access to All Exams",
        "100% Replica of the Bluebook/TestNav Platform",
        "2 Adaptive Full Length SATs",
        "4 Full Length ACTs",
        "2 Full Length APs (Coming Soon)",
        "1 Full Length PSAT (Coming Soon)",
        "Immediate Scoring",
        "Interactive Answer Explanations",
        "Time Tracking & Proctoring",
        "AI Performance Analysis",
        "Intelligent Flash Cards"
      ],
      proList: [
        "Immediate Scoring",
        "<b>Unlimited</b> Interactive Answer Explanations ",
        "Time Tracking & Proctoring",
        "AI Performance Analysis",
        "Intelligent Flash Cards",
        "AI Smart Score Projector",
        "Focused Practice Drills",
        "AI-Powered Study Plan"
      ],
      maxList: [
        "<b>Unlimited Access</b> to All Exams",
        "100% Replica of the Bluebook/TestNav Platform",
        "<b>20</b> Adaptive Full Length SATs",
        "<b>40</b> Full Length ACTs",
        "<b>20</b> Full Length APs (Coming Soon)",
        "<b>6</b> Full Length PSATs (Coming Soon)",
        "Immediate Scoring",
        "<b>Unlimited</b> Interactive Answer Explanations ",
        "Time Tracking & Proctoring",
        "AI Performance Analysis",
        "Intelligent Flash Cards",
        "AI Smart Score Projector",
        "Focused Practice Drills",
        "AI-Powered Study Plan"
      ],
      moment,
      isLogin: true,
      // proTimeLimited: "",
      langIsEn: "",
      isTW: false
    };
  },
  computed: {
    offersTime() {
      return "";
    },
    pageTitle() {
      return this.$t("pageTitle.PlansAndPricing");
    },
    isPro() {
      return this.$store.getters["user/getIsPro"];
    },
    proTimeLimited() {
      return this.$store.getters["user/getProTimeLimited"];
    },
    isLifetime() {
      let isLifetime = false;
      if (this.proTimeLimited) {
        isLifetime =
          moment("2099-01-01").format("YYYY-MM-DD") ===
          moment(this.proTimeLimited).format("YYYY-MM-DD");
      }
      return isLifetime;
    },
    discountDate() {
      let date = moment().format("YYYY-MM-DD");
      let today = moment().format("D");
      if (today > 15) {
        date = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else {
        date = moment()
          .date(15)
          .format("YYYY-MM-DD");
      }
      return date;
    }
  },
  watch: {},

  mounted() {
    let index = 0;
    this.interval = setInterval(() => {
      index = index + 1;
      if (index > this.ProTypes.length - 1) {
        index = 0;
      }
      this.ProType = this.ProTypes[index];
    }, 2000);
  },

  methods: {
    setProType(ProType) {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.ProType = ProType;
    },
    async upgradePlans(category) {
      let extendInformation = {};
      switch (category) {
        case "LifeTime":
          extendInformation = {
            plan_id: 15
          };
          break;
        case "Year":
          extendInformation = {
            plan_id: 19
          };
          break;
        case "Month":
          extendInformation = {
            plan_id: 18
          };
          break;
        default:
      }
      try {
        if (this.isPro && category === "Year") {
          let extendProTimeLimited = moment(this.proTimeLimited);
          let information = {
            month: 12,
            suffix: "s",
            date: extendProTimeLimited.add(1, "years").format("YYYY-MM-DD")
          };
          await this.$confirm(
            this.$t("plans.extendNotice", information),
            this.$t("message.notice"),
            {
              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("message.cancel"),
              type: "warning"
            }
          );
        }
        if (this.isPro && category === "Month") {
          let extendProTimeLimited = moment(this.proTimeLimited);
          let information = {
            month: 1,
            suffix: "",
            date: extendProTimeLimited.add(1, "months").format("YYYY-MM-DD")
          };
          await this.$confirm(
            this.$t("plans.extendNotice", information),
            this.$t("message.notice"),
            {
              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("message.cancel"),
              type: "warning"
            }
          );
        }
      } catch (e) {
        return false;
      }
      try {
        const res = await plansApi.upgradePlan(extendInformation);
        if (res.url) {
          window.location.href = res.url;
        }
        this.$store.dispatch("user/getProfile");
      } catch (e) {
        //
      }
    }
  }
};
</script>
<style scoped lang="scss">
.plan {
  text-align: left;
}
.switch-date {
  border: 1px solid #ccc;
  border-radius: 1rem;
  width: 180px;
  display: flex;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
.switch-button {
  border-radius: 0.5rem;
  width: 50%;
  padding: 0.5rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}
.switch-active {
  background-color: var(--themeColor);
  color: white;
}
.plan-cover {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 1rem;
}
.plan-item {
  padding: 1rem 1.5rem;
  flex: 1;
}
.plan-item:not(:last-child) {
  border-right: 1px solid #ccc;
}
.plan-item h5 {
  font-size: 1rem;
  max-height: 1rem;
}
.plan-fee {
  font-size: 4rem;
  font-weight: 700;
}
.free-text {
  color: #01b884;
}
.money-text {
  color: var(--themeColor);
}
.plan-button {
  margin-top: 1rem;
  // margin-bottom: 3rem;
  color: white !important;
  font-size: 1rem;
  font-weight: 500;
  background-color: var(--themeColor);
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-image 0.5s ease;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: box-shadow 0.3s, transform 0.3s;
}
.plan-button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}
.plan-options {
  // color: var(--themeColor);
  // line-height: 28px;
  font-size: 14px;
}
.plan-option {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin: 10px 0;
}
.plan-option .icon {
  font-weight: 700;
  margin-right: 0.8rem;
}
.free-button {
  background-color: #01b884;
}
.money-button {
  background-color: #fa17e3;
  background-color: #c8c9cc;
  cursor: not-allowed;
}
.contact-button {
  background-color: var(--themeColor);
}
@media screen and (max-width: 768px) {
  .plan-container {
    max-width: 100%;
    padding: 0 15px 20px 15px;
  }
  .plan-cover {
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
  .plan-item {
    width: 35rem !important;
    word-break: normal !important;
    // border: 1px solid #ccc;
    // margin-bottom: 2rem;
    // border-radius: 1rem;
  }
  .plan-option {
    font-size: 0.8rem;
    line-height: 1.6rem;
  }
  .switch-date {
    width: 100%;
  }
}
</style>
<style scoped>
.plan-pro.plan-item h2,
.plan-pro.plan-item .money-text {
  color: #fa12e3;
}
.plan-pro .contact-button {
  background-color: #fa12e3;
}
.plan-max.plan-item h2,
.plan-max.plan-item .money-text {
  color: #1f7cff;
}
.plan-max .contact-button {
  background-color: #1f7cff;
}
.plan-tag {
  font-size: 1rem;
  color: white;
  display: inline-block;
  line-height: 2rem;
  padding: 0 1rem;
  margin-left: 0.5rem;
  border-radius: 1rem;
}
.plan-max .plan-tag {
  background-color: #1f7cff;
}
.price-text {
  color: #7f76b3;
}
.pro-type {
  cursor: pointer;
}
.pro-type:hover,
.pro-type.activePro {
  text-decoration: underline;
  color: #fa12e3;
  font-weight: 700;
}
</style>
