<template>
  <div>
    <Landing
      :role="role"
      :tabs="tabs"
      :mainRouter="`StudentHome`"
      :subTitle="subTitle"
      :mainTabs="mainTabs"
      :stories="stories"
      :storiesTitle="storiesTitle"
    ></Landing>
    <div class="container plan-container mb-4">
      <Plan></Plan>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Landing from "@/views/landingPage/components/Index.vue";
import Plan from "@/views/plans/Students.vue";

export default {
  metaInfo() {
    return {
      title: `For ${this.role} - ` + this.CompanyName
    };
  },

  components: { Landing, Plan },

  mixins: [],

  props: [],
  data() {
    return {
      //Role
      role: "Students",
      //SubTitle
      subTitle: {
        title: "The everything site for test prep",
        smallTitle:
          "Powered by AI, XMocks is the everything site from your first diagnostic to your real test."
      },
      // Tabs
      tabs: [
        {
          icon: "fas fa-pencil-ruler",
          title: "Interactive Answer Explanations",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Answer+Explanations.png"
        },
        {
          icon: "fas fa-stopwatch",
          title: "Time Tracking & Proctoring",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Time+Tracking.png"
        },
        {
          icon: "fas fa-grin-stars",
          title: "AI-Powered Study Plan",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/AI-Powered+Study+Plan.png"
        },
        {
          icon: "fas fa-house-user",
          title: "Adaptive Practice Tests",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Adaptive+Practice+Tests.png"
        },
        {
          icon: "fas fa-chart-bar",
          title: "Performance Analysis",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Performance+Analysis.png"
        },
        {
          icon: "fas fa-mail-bulk",
          title: "Smart Score Projector",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Drills.png"
        },
        {
          icon: "fas fa-spell-check",
          title: "Focused Practice Drills",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Individual+Modules.png"
        },
        {
          icon: "fas fa-book",
          title: "Intelligent Flash Cards",
          img:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/Flash+Cards.png"
        }
      ],
      // mainTabs
      mainTabs: [
        {
          title: "Make a plan",
          text:
            "Get started by creating a game plan for your test prep by using our AI-powered study plan generator",
          img: ""
        },
        {
          title: "Drill & Review",
          text:
            "Master every concept with detailed answer explanations and targeted practice designed to boost your score",
          img: ""
        },
        {
          title: "Crush goals",
          text:
            "Track your progress and achieve your goals with data-driven insights and real-time adjustments",
          img: ""
        }
      ],
      // Stories
      stories: [
        {
          type: "video",
          video: "https://www.youtube.com/shorts/vk7XzgWNO44",
          img: "",
          cover:
            "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/users/student.png",
          words: "Last year, I was struggling with the SAT and had a score of 1160.",
          name: "Jessica",
          title: "Jessica"
        },
        {
          type: "img",
          video: "",
          img: "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/users/Jessica.png",
          cover: "",
          name: "Jessica",
          title: "HS Senior",
          words: "Last year, I was struggling with the SAT and had a score of 1160. But after doing consistent drills and practice tests on SAT Mocks, my score skyrocketed by 400 points, and I ended up with a 1560! The study plan, answer explanations, and detailed performance feedback really made all the difference."
        },
        {
          type: "img",
          video: "",
          img: "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/users/Alex.png",
          cover: "",
          name: "Alex",
          title: "HS Senior",
          words: "I used SAT Mocks for two years, and the results speak for themselves. I just got a perfect 1600! I love the AI answer explanations to help me understand the material inside and out. Game-changer."
        },
        {
          type: "img",
          video: "",
          img: "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/users/Jerome.png",
          cover: "",
          name: "Jerome",
          title: "HS Senior",
          words: "When I started using ACT Mocks, my ACT score was 23. After a year of using the platform, I saw my score jump by 12 points to a 35! The customized practice and progress tracking were crucial for my improvement."
        },
        {
          type: "img",
          video: "",
          img: "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/users/Evan.png",
          cover: "",
          name: "Evan",
          title: "HS Senior",
          words: "I needed a 32 for a direct admit as a student athlete, but it felt impossible studying on my own. I studied with a tutor and used ACT Mocks extensively. The combination of personalized tutoring and the platform's practice exams helped me feel confident and prepared every step of the way. I ended up with a 33!"
        },
        {
          type: "img",
          video: "",
          img: "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/users/Emma.png",
          cover: "",
          name: "Emma",
          title: "HS Senior",
          words: "I was really nervous about my AP US History test, but after using AP Mocks this past year, I feel prepared for the exam in May. I'm scorings 5s on all my practice tests!"
        },
        {
          type: "img",
          video: "",
          img: "https://ivy-way.s3.ap-northeast-1.amazonaws.com/X-Mocks/LandingPage-Students/users/Liam.png",
          cover: "",
          name: "Liam",
          title: "Homeschooled Sophomore",
          words: "I used AP Mocks to study for AP Stats and AP Psychology without taking official classes. Thanks to the platform, I got college credit for both exams! The resources were perfect for self-studying. AP Mocks was my key to success."
        }
      ],
      storiesTitle: {
        title: "Student Success Stories",
        smallTitle:
          "Perfect scores, dramatic improvement, and AP test domination<br />These students used XMocks to crush their goals!",
        count: "4,000"
      }
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.plan-container {
  max-width: calc(1280px + 2 * var(--layout-padding));
  margin: 0 auto;
  padding: 0 var(--layout-padding);
  width: auto;
}
@media screen and (max-width: 768px) {
  .plan-container {
    max-width: 100%;
    padding: 0 15px 20px 15px;
  }
}
</style>